import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout, SEO } from '../components'

const Projects = ({ data }) => {
  return (
    <Layout>
      <SEO title="Projects" />

      <section role="main" className="container pt-5 pb-0 text-center">
        <h1 className="mt-md-5 mb-4">The projects speak volumes about us.</h1>
        <p className="lead mb-5">
          We work with some of the most successful companies from Malaysia.
        </p>
      </section>

      <section className="container story pb-5">
        <div className="row no-gutters rounded overflow-hidden flex-md-row shadow">
          <div className="col-md">
            <Img
              className="img-fluid"
              fluid={data.exchange106.childImageSharp.fluid}
              alt="Exchange 106 crown taken from our drone"
              style={{ height: '100%', width: 'auto' }}
              imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
            />
          </div>
          <div className="col p-4 d-flex flex-column position-static">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Malaysia's tallest building</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title text-uppercase">The Exchange 106</h3>
                <p className="text-primary">
                  492 metres high<br />
                  106 levels & 6 basements<br />
                  240,000 sqm nett lettable area
                </p>
                <p className="mb-0">At the heart of the Tun Razak Exchange (TRX) development is the centrepiece Exchange 106, a landmark that has already surpassed the Petronas Twin Towers as the tallest building in the country. At 106 storeys, the modern icon is nestled in a park setting and is designed for to sustainable specifications and standards, with certification under the Green Building Index (GBI) and Leadership in Energy and Environmental Design (LEED).</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container story pb-5">
        <div className="row no-gutters rounded overflow-hidden flex-md-row shadow">
          <div className="col-md">
            <Img
              className="img-fluid"
              fluid={data.trx.childImageSharp.fluid}
              alt="Tun Razak Exchange"
              style={{ height: '100%', width: 'auto' }}
              imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
            />
          </div>
          <div className="col p-4 d-flex flex-column position-static">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Kuala Lumpur's new future-ready CBD</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title text-uppercase">Tun Razak Exchange</h3>
                <p className="text-primary">
                  70 acres with GDV of MYR40 billion<br />
                  24 million sqft GFA<br />
                  30 buildings on site
                </p>
                <p className="mb-0">Excellent connectivity, iconic public realms and smart and sustainable design come together to create Malaysia's first international financial district at Tun Razak Exchange (TRX). Designed as KL's best-connected address, TRX's 70-acre masterplan includes a dedicated MRT interchange station and direct accesses to Jalan Tun Razak, MEX and SMART Highways, as well as the upcoming DUKE 3 Highway.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container story pb-5">
        <div className="row no-gutters rounded overflow-hidden flex-md-row shadow">
          <div className="col-md">
            <Img
              className="img-fluid"
              fluid={data.ophir.childImageSharp.fluid}
              alt="Ophir Wellhead Platform"
              style={{ height: '100%', width: 'auto' }}
              imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
            />
          </div>
          <div className="col p-4 d-flex flex-column position-static">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">World's first offshore platform relocation via suction pile method</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title text-uppercase">Ophir Wellhead Platform</h3>
                <p className="text-primary">
                  1,100-tonne jacket with 350-tonne topside<br />
                  14-metre deep suction piles<br />
                  Water depth at 73 metres
                </p>
                <p className="mb-0">Situated 200km of the Terengganu coastline, the Ophir wellhead platform is the first of its kind to feature a tri-legged acket with integrated suction pile foundation, making the offshore installation safe and swift in a record breaking 72 hours.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container story pb-5">
        <div className="row no-gutters rounded overflow-hidden flex-md-row shadow">
          <div className="col-md">
            <Img
              className="img-fluid"
              fluid={data.mrt2.childImageSharp.fluid}
              alt="MRT2 Line 2 Underground"
              style={{ height: '100%', width: 'auto' }}
              imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
            />
          </div>
          <div className="col p-4 d-flex flex-column position-static">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Commuting half-a-million people daily</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title text-uppercase">MRT2 Line 2 Underground</h3>
                <p className="text-primary">
                  11 underground stations over 13.5km<br />
                  MYR13 billion construction cost
                </p>
                <p className="mb-0">The SSP Line is the second MRT line being developed, serving a corridor stretching from Sungai Buloh to Putrajaya that includes Jalan Tun Razak, KLCC, Tun Razak Exchange and Cyberjaya, among others. At commencement of full service in the second quarter of 2022, the SSP Line is expected to have a ridership of 529,000 passengers per day.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container story pb-5">
        <div className="row no-gutters rounded overflow-hidden flex-md-row shadow">
          <div className="col-md">
            <Img
              className="img-fluid"
              fluid={data.ikea.childImageSharp.fluid}
              alt="IKEA South-East Asia"
              style={{ height: '100%', width: 'auto' }}
              imgStyle={{ objectFit: 'cover', objectPosition: 'left bottom' }}
            />
          </div>
          <div className="col p-4 d-flex flex-column position-static">
            <div className="card border-0 bg-transparent">
              <div className="card-header px-0">
                <small className="text-uppercase">Swedish charm grows nationwide</small>
              </div>
              <div className="card-body pt-0 px-0">
                <h3 className="card-title text-uppercase">IKEA south-east asia projects</h3>
                <p className="text-primary">
                  2 new developments in Penang and Johor<br />
                  Redevelopment of flagship property<br />
                  1.4 million sqft of commercial area
                </p>
                <p className="mb-0">The Swedish furniture brand continues to strengthen in Malaysia through its latest stores in IKEA Batu Kawan, Penang and IKEA Tebrau + Toppen Shopping Centre, Johor, with the latter expecting to attract 15 million visitors by 2020 including those living in Singapore. In addition, the IPC Shopping Centre that connects to flagship store IKEA Damansara has undergone a major facelift that showcases 145 stores of existing and new tenants, revitalised with a Scandinavian-inspired interior.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bg-dark text-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <i className="material-icons md-36 mb-2">camera_enhance</i>
              <h3 className="font-weight-bold mb-1">
                Give your project the best view
              </h3>
              <p className="lead mb-5 mb-md-0">
                We'll take it above and beyond, literally.
              </p>
            </div>
            <div className="col-12 col-md-auto">
              <a href="/contact/" className="btn btn-lg btn-primary">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query {
    exchange106: file(relativePath: {eq: "project-exchange106.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trx: file(relativePath: {eq: "project-trx.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ophir: file(relativePath: {eq: "project-ophir.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mrt2: file(relativePath: {eq: "project-mrt2.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ikea: file(relativePath: {eq: "project-ikea.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`